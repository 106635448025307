// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-brewskis-catering-jsx": () => import("./../../../src/pages/brewskis-catering.jsx" /* webpackChunkName: "component---src-pages-brewskis-catering-jsx" */),
  "component---src-pages-brewskis-restaurant-jsx": () => import("./../../../src/pages/brewskis-restaurant.jsx" /* webpackChunkName: "component---src-pages-brewskis-restaurant-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-loyalty-points-mr-liquor-jsx": () => import("./../../../src/pages/loyalty-points-mr-liquor.jsx" /* webpackChunkName: "component---src-pages-loyalty-points-mr-liquor-jsx" */),
  "component---src-pages-menu-jsx": () => import("./../../../src/pages/menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */),
  "component---src-pages-mr-liquor-store-jsx": () => import("./../../../src/pages/mr-liquor-store.jsx" /* webpackChunkName: "component---src-pages-mr-liquor-store-jsx" */)
}

